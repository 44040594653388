import * as ACTIONS from '../store/actions/actions'

import { Link, useNavigate } from 'react-router-dom'

// BT
import BtBig from '../components/BtBig'
// Store
import { Context } from '../store/Store'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { MenuAlt2Icon } from '@heroicons/react/outline'
import Notifications from '../components/Notifications'
import { Oval } from 'react-loader-spinner'
import React from 'react'
// K
import Subtitle from '../components/Subtitle'
import adherentsSvg from '../svg/adherents.svg'
// Icones
import annoncesSvg from '../svg/annonces.svg'
import axios from 'axios'
import planificationSvg from '../svg/planification.svg'
import priceSvg from '../svg/price.svg'

// Hooks

var _ = require('lodash')

export const renderNotificationActus = (app) => {
  if (!app.notifications) return
  if (app.notifications.some((n) => n.subject === 'actus'))
    return <Notifications />
}

export const renderNotificationMessages = (app) => {
  if (!app.notifications) return
  if (app.notifications.some((n) => n.subject === 'messages'))
    return <Notifications />
}

export const renderNotification = (app) => {
  if (!app.notifications) return
  else return <Notifications />
}

export const Home = React.memo(function Home() {
  const { app, dispatch } = React.useContext(Context)
  const [loading, setLoading] = React.useState(true)
  const [, setError] = React.useState(null)
  const user = window.localStorage.getItem('user')
  const navigate = useNavigate()

  React.useEffect(() => {
    if (app.refresh) {
      let u = JSON.parse(user)
      axios
        .get(
          `${
            process.env.REACT_APP_APOLLO_URL || 'https://extranet.biobreizh.org'
          }/wp-json/bb/v1/producteur/${u.id}`
        )
        .then(function (response) {
          if (response.status === 200) {
            dispatch(ACTIONS.setLegumes(response.data.legumes))
            dispatch(ACTIONS.setMessages(response.data.messages))
            dispatch(ACTIONS.setNews(response.data.news))
            dispatch(ACTIONS.setDocs(response.data))
            dispatch(
              ACTIONS.setFiches(
                _.orderBy(response.data.fiches, ['name'], ['asc'])
              )
            )
            dispatch(ACTIONS.setAllLegumes(response.data.alllegumes))
            dispatch(ACTIONS.setPrice(response.data.prix))
            dispatch(ACTIONS.setPo(response.data.po))
            dispatch(ACTIONS.setPoTextes(response.data.po_textes))
            dispatch(ACTIONS.setPoArchives(response.data.po_archives))
            dispatch(ACTIONS.setProfil(response.data.profil))
            dispatch(ACTIONS.setClients(response.data.clients))

            dispatch(ACTIONS.setQualite(response.data.qualite))

            dispatch(ACTIONS.refresh(false))
            setLoading(false)
          } else {
            setError('Erreur de connexion, veuillez vérifier vos identifiants')
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      setLoading(false)
    }
  }, [app.refresh])

  if (!loading) {
    if (!app)
      return (
        <span
          onClick={() => {
            window.localStorage.removeItem('user')
            window.location = '/'
          }}
        >
          <Subtitle title="Se déconnecter" classCustom="text-red-500" />
        </span>
      )
    return (
      <div className="flex h-full flex-col justify-between">
        <Header
          left={
            <div className="w-full flex text-white text-center items-center"></div>
          }
          right={
            <button
              type="button"
              className="pl-2 border-l border-gray-200 text-white focus:outline-none md:hidden"
              onClick={() => dispatch(ACTIONS.setMenuOpen(!app.menuOpen))}
            >
              <span className="sr-only">Ouvrir</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          }
          title={
            <div className="flex justify-center">
              <img
                className="w-8 h-8"
                src="/logo192.png"
                alt="Logo Biobreizh"
              />{' '}
              <span className="font text-2xl tracking-wide text-white md:text-black">
                BIOBREIZH
              </span>
            </div>
          }
        />

        <div className="overflow-auto md:pl-64 ">
          <div className="max-w-5xl mx-auto px-6 py-6">
            <div className="mb-5">
              <div className="grid grid-cols-2 mb-5 gap-5">
                <Link to="/annonces">
                  <BtBig
                    icon={annoncesSvg}
                    title="Annonces"
                    classCustom="border border-1 bg-white min-h-[180px]"
                    titleColor="text-black"
                  />
                </Link>
                <Link to="/price">
                  <BtBig
                    icon={priceSvg}
                    title="Prix"
                    classCustom="border border-1 bg-white  min-h-[180px]"
                    titleColor="text-black"
                  />
                </Link>
                <Link to="/biobreizh/organochlores">
                  <BtBig
                    icon={planificationSvg}
                    title="DECLARATION PARCELLES CUCURBITACEES"
                    classCustom="bg-red-500 text-center border border-1 bg-white  min-h-[180px]"
                    titleColor="text-white"
                  />
                </Link>

                {/* <Link to="/po/">
                  <BtBig
                    icon={planificationSvg}
                    title="Aides"
                    classCustom="bg-red-500 text-center border border-1 bg-white px-4 min-h-[180px]"
                    titleColor="text-white"
                  />
                </Link> */}
                <Link to="/adherents">
                  <BtBig
                    icon={adherentsSvg}
                    title="Annuaire adhérents"
                    classCustom="border text-center border-1 bg-white  min-h-[180px]"
                    titleColor="text-black"
                  />
                </Link>
              </div>

              <div className="">
                <div
                  className="bg-white shadow-lg p-4 rounded-lg cursor-pointer mb-5 relative"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/messages')
                  }}
                >
                  {app.notifications.length > 0 && (
                    <div className="absolute right-2">
                      {' '}
                      {renderNotificationMessages(app)}
                    </div>
                  )}
                  <Subtitle
                    title="Message"
                    classCustom="text-red-500 uppercase"
                  />
                  <hr className="mt-0 mb-2 md:mt-2 md:mb-4" />
                  <div className="flex">
                    <div className="mb-5 md:mb-0 mr-5 w-2/4  md:w-1/4">
                      <img
                        className="object-cover h-24 md:h-48 w-36 md:w-96 shadow-lg "
                        src={app.messages[0].img}
                        alt=""
                      />
                    </div>
                    <div className="w-2/4 md:w-3/4">
                      <p className="text-sm tracking-tight text-gray-600">
                        {app.messages[0].date}
                      </p>
                      <h2
                        className="text-md font-extrabold tracking-tight text-gray-900 mb-4 xs:mb-0 leading-tight md:mb-2"
                        dangerouslySetInnerHTML={{
                          __html: app.messages[0].title,
                        }}
                      ></h2>
                      <div className="md:hidden text-xs">
                        {app.messages[0].subtitle}
                      </div>
                      <div
                        className="dangerouslySetInnerHTML hidden md:block"
                        dangerouslySetInnerHTML={{
                          __html: app.messages[0].excerpt,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className="bg-white shadow-lg p-4 rounded-lg cursor-pointer relative"
                  onClick={(e) => {
                    e.preventDefault()
                    navigate('/news')
                  }}
                >
                  {app.notifications.length > 0 && (
                    <div className="absolute right-2">
                      {' '}
                      {renderNotificationActus(app)}
                    </div>
                  )}
                  <Subtitle title="Actu" classCustom="text-red-500 uppercase" />
                  <hr className="mt-0 mb-2 md:mt-2 md:mb-4" />
                  <div className="flex">
                    <div className="mb-5 md:mb-0 mr-5 w-2/4  md:w-1/4">
                      <img
                        className="object-cover h-24 md:h-48 w-36 md:w-96 shadow-lg "
                        src={app.news[0].img}
                        alt=""
                      />
                    </div>
                    <div className="w-2/4 md:w-3/4 relative">
                      <p className="text-sm tracking-tight text-gray-600">
                        {app.news[0].date}
                      </p>
                      <h2
                        className="text-md font-extrabold tracking-tight text-gray-900 mb-4 xs:mb-0 leading-tight md:mb-2"
                        dangerouslySetInnerHTML={{
                          __html: app.news[0].title,
                        }}
                      ></h2>
                      <div className="md:hidden text-xs">
                        {app.news[0].subtitle}
                      </div>
                      <div
                        className="dangerouslySetInnerHTML hidden md:block"
                        dangerouslySetInnerHTML={{
                          __html: app.news[0].excerpt,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    )
  } else {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Oval
          type="Oval"
          color="#48BFAD"
          height={100}
          width={100} //3 secs
        />
      </div>
    )
  }
})
